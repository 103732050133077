import React, {Component} from "react";
import {accessControl} from "../../../../permission/permission";
class CategoryForm extends Component{
    constructor() {
        super();
        accessControl([1, 4]);
    }
    render() {
        return (
            <form onSubmit={this.props.handleSubmit}>
                <div className="card-body"  style={{textAlign: 'left'}}>
                    <div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll text-left" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

                        <div className="row mb-5">
                            <div className="col-md-6 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Category</label>
                                <input
                                    type="text"
                                    className="form-control form-control-solid  mb-5"
                                    name="categoryName"
                                    id="categoryName"
                                    onChange={this.props.onEdit}
                                    value={this.props.data.categoryName}
                                    placeholder="category name"
                                />
                            </div>

                            <div className="col-md-6 fv-row">
                                <button type="submit" id="kt_modal_new_address_submit" data-kt-indicator={this.props.isLoading} className="btn btn-primary w-100  mb-9 mt-9">
                                    <span className="indicator-label">Submit</span>
                                    <span className="indicator-progress">Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"/>
                             </span>
                                </button>
                            </div>

                        </div>




                    </div>
                </div>
            </form>
        );
    }
}

export default CategoryForm;