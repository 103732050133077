import React, { Component } from "react";
import { projectName, projectUploadAPI } from "../../../resources/url";
import SimpleFileUpload from "react-simple-file-upload";
class SliderForm extends Component {
    render() {
        return (
            <form onSubmit={this.props.handleSubmit}>
                <div className="card-body" style={{ textAlign: 'left' }}>
                    <div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll text-left" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
                        <div className="row mb-5">
                            <div className="col-md-6 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Slider Title</label>
                                <input
                                    type="text"
                                    className="form-control form-control-solid  mb-5"
                                    name="title"
                                    id="title"
                                    onChange={this.props.onEdit}
                                    value={this.props.data.title}
                                    placeholder="Slider Title"
                                />
                            </div>
                            <div className="col-md-6 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Caption </label>
                                <input
                                    type="text"
                                    className="form-control form-control-solid  mb-5"
                                    name="caption"
                                    id="caption"
                                    onChange={this.props.onEdit}
                                    value={this.props.data.caption}
                                    placeholder="Caption"
                                />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-6 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Button Name</label>
                                <input
                                    type="text"
                                    className="form-control form-control-solid  mb-5"
                                    name="btnName"
                                    id="btnName"
                                    onChange={this.props.onEdit}
                                    value={this.props.data.btnName}
                                    placeholder="Button Name"
                                />
                            </div>
                            <div className="col-md-6 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Button Link </label>
                                <input
                                    type="text"
                                    className="form-control form-control-solid  mb-5"
                                    name="btnLink"
                                    id="btnLink"
                                    onChange={this.props.onEdit}
                                    value={this.props.data.btnLink}
                                    placeholder="Button Link"
                                />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-6 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Image</label>
                                {/* <SimpleFileUpload
                                    apiKey={projectUploadAPI}
                                    onSuccess={this.props.onImageChange}
                                    tag={`${projectName}-slider`}
                                    accepted="image/*"
                                    maxFileSize={1}
                                    preview="true"
                                    width="100%"
                                /> */}
                                <small className="text-danger"><strong>File must not exceed 1MB</strong></small>
                                {/* <input
                                    type="file"
                                    className="form-control form-control-solid  mb-5"
                                    name="photo"
                                    id="photo"
                                    onChange={this.props.onImageChange}
                                    accept="image/*"
                                /> */}

                                <div className="mb-3">
                                    <div className="fv-row mb-2">
                                        <div className="dropzone" id="kt_ecommerce_add_product_media" onClick={() => {
                                            document.getElementById("photo").click()
                                        }}>
                                            <div className="dz-message needsclick">
                                                <i className="bi bi-image text-primary fs-3x" />
                                                <div className="ms-4 col-md-9">
                                                    <h3 className="fs-5 fw-bold text-gray-900 mb-1">Click to upload.</h3>
                                                    <span className="fs-7 fw-semibold text-gray-400 text-info"> {this.props.data.image_name !== "" ? this.props.data.image_name : "Only .jpg, .png, .jpeg are allowed"}</span>
                                                </div>
                                                <div className="col-md-2">{this.props.image !== "" ? <img className="img-thumbnail" width={120} height={100} srcSet={this.props.image !== "" ? this.props.image : ""} /> : ""}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <span className="alert-danger"> Max of 2MB file is allowed!</span>
                                </div>
                                <input type="file" id="photo" name={"photo"} accept={"image/*"} onChange={this.props.onImageChange} hidden />

                            </div>
                            <div className="col-md-6 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Image Position</label>
                                <select
                                    className="form-control form-control-solid"
                                    name="imagePosition"
                                    id="imagePosition"
                                    onChange={this.props.onEdit}
                                    value={this.props.data.imagePosition}
                                >
                                    <option value="">Select Image Position</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                </select>
                            </div>
                        </div>

                        <button type="submit" id="kt_modal_new_address_submit" data-kt-indicator={this.props.isLoading} className="btn btn-primary w-25 offset-sm-4 mb-9 mt-9">
                            <span className="indicator-label">Submit</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2" />
                            </span>
                        </button>
                    </div>
                </div>
            </form>
        );
    }
}

export default SliderForm;