import React, {Component} from "react";
import PartnersForm from "./partnersform";
import axios from "axios";
import swal from 'sweetalert';
import {serverLink} from "../../../resources/url";
import Header from "../../Header/header";
import {connect} from "react-redux";

class AddPartners extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: "off",
            imagefile: "",
            htmlelement: <i className="fa fa-user fa-8x" aria-hidden="true"/>,
            formData: {
                PartnerName: "",
                ImagePath: "",
                image: "",
                Status: "1",
                photo: ""
            },
        }
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: "on",
        });
        this.onAddPartner();
    };

    async onAddPartner() {
        let sendData = {
            ...this.state.formData,
            InsertedBy: `${this.props.loginDetails[0].FirstName} ${this.props.loginDetails[0].MiddleName} ${this.props.loginDetails[0].Surname}`,
        };

        if(this.state.formData.PartnerName === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please enter PartnerName", "error")
            return false;
        }

        axios.post(`${serverLink}general/partner/add`, sendData)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if(response === "exist") {
                    this.showAlert("Oops!", "Partner with the same name already exist", "error")
                    return false;
                } else if (response === "failed") {
                    this.showAlert("Oops!", "Something went wrong adding the partner. Please try again!", "error")
                    return false;
                } else if (response === "success"){

                    if (this.state.formData.photo !== "") {
                        const formData = new FormData();
                        formData.append('photo', this.state.formData.photo);
                        formData.append('entry_id', result.data.entry_id)
                        axios.patch(`${serverLink}general/partners_upload`, formData)
                            .then(result => {
                                this.setState({
                                    isLoading: false,
                                });
                            })
                            .catch(err => {
                                this.setState({
                                    isLoading: false,
                                });
                                console.error('ERROR', err);
                            });
                    }
                    this.showAlert("SUCCESS", "PARTNER ADDED SUCCESSFULLY", "success")
                    return false;
                } else {
                    this.showAlert("Oops!", "Failed to add the partner details!", "error")
                    return false;
                }

            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                console.error('ERROR', err);
            });
    }

    onEdit = (e) => {
        const formData = this.state.formData;
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    onImageChange = (event) => {
        const formData = this.state.formData;
        this.setState({formData: {
                ...formData, photo: event.target.files[0]
            },});
        // if (event.target.files && event.target.files[0]) {
        //     this.setState({
        //         imagefile: event.target.files[0],
        //     });
        //
        //     let reader = new FileReader();
        //     reader.onload = (e) => {
        //         this.setState({
        //             htmlelement: (
        //                 <div className="addpersonpage">
        //                     <img
        //                         className="netimage"
        //                         srcSet={e.target.result}
        //                         alt="profileImage"
        //                     />
        //                 </div>
        //             ),
        //         });
        //     };
        //     reader.readAsDataURL(event.target.files[0]);
        //     this.setState({});
        // }
    };

    showAlert(title, msg, type){
        return swal({
            title: title,
            text: msg,
            icon: type,
            button: "OK",
        }).then((value) => {
            window.location.href = '/general/partners/list';
        });
    }

    render() {
        return (
            <>
                <Header isFaculty={false}/>
                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                    <div className="toolbar" id="kt_toolbar">
                        <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                            <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
                                <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">General
                                    <span className="h-20px border-dark  border-start ms-3 mx-2"/>
                                    Partner
                                    <span className="h-20px border-dark  border-start ms-3 mx-2"/>
                                    Add Partner
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className="post d-flex flex-column-fluid" id="kt_post">
                        <div id="kt_content_container" className="container-fluid">
                            {/* CONTENT START */}
                            <div className="card mb-5 mb-xl-10">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h3>ADD PARTNER</h3>
                                    </div>
                                </div>
                                <PartnersForm
                                    handleSubmit={this.handleSubmit}
                                    onEdit={this.onEdit}
                                    onImageChange={this.onImageChange}
                                    isLoading={this.state.isLoading}
                                    data={this.state.formData}
                                />
                            </div>
                            {/* CONTENT END */}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loginDetails: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(AddPartners);

