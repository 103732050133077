import React, {Component} from "react";
import Newsform from "./newsform";
import axios from "axios";
import swal from 'sweetalert';
import {serverLink} from "../../resources/url";
import Header from "../Header/header";
import {connect} from "react-redux";

class AddNews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: "off",
            imagefile: "",
            htmlelement: "",
            formData: {
                title: "",
                image: "",
                status: "",
                content: "",
                FacultyCode: this.props.isFaculty  ? this.props.facultyData.FacultyCode : "General",
                photo: "",
                ImagePath: "",
            },
        }
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: "on",
        });
        this.onAddNews();
    };

    async onAddNews() {
        let sendData = {
            ...this.state.formData,
            submittedBy: `${this.props.loginDetails[0].FirstName} ${this.props.loginDetails[0].MiddleName} ${this.props.loginDetails[0].Surname}`,
        };

        if(this.state.formData.title === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please enter news title", "error")
            return false;
        }

        if(this.state.formData.content === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please enter news content", "error")
            return false;
        }
        axios.post(`${serverLink}news/add`, sendData)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if(response === "news exist") {
                    this.showAlert("Oops!", "News with the same name already exist", "error")
                    return false;
                } else if (response === "failed") {
                    this.showAlert("Oops!", "Something went wrong adding the news. Please try again!", "error")
                    return false;
                } else if (response === "success") {

                    if (this.state.imagefile !== "") {
                        const formData = new FormData();
                        formData.append('photo', this.state.imagefile);
                        formData.append('entry_id', result.data.entry_id)
                        axios.patch(`${serverLink}news/uploadNewsPhoto`, formData)
                            .then(result => {
                                this.setState({
                                    isLoading: false,
                                });
                            })
                            .catch(err => {
                                this.setState({
                                    isLoading: false,
                                });
                                console.error('ERROR', err);
                            });
                    }
                        this.showSuccessAlert("SUCCESS", "NEWS ADDED SUCCESSFULLY", "success")
                        return false;
                    } else {
                        this.showAlert("Oops!", "Failed to add the news details!", "error")
                        return false;
                    }

            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                console.error('ERROR', err);
            });
    }

    onEdit = (e) => {
        const formData = this.state.formData;
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    // onImageChange = (url) => {
    //     const formData = this.state.formData;
    //     this.setState({formData: {
    //             ...formData, photo: url
    //         },});
    // };

    onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0]
            if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {
            } else {
                this.showAlert("Oops!", "Only .png, .jpg and .jpeg format allowed!", "error")
                return false;
            }
            if (file.size > 2000000) {
                this.showAlert("Oops!", "max file size is 2mb", "error")
                return false;
            }

            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
                this.setState({
                    imagefile: event.target.files[0],
                    htmlelement: e.target.result,
                    image_name: file.name,
                    view: file.mozFullPath,
                });
            };
        }
    };

    showAlert(title, msg, type){
        return swal({
            title: title,
            text: msg,
            icon: type,
            button: "OK",
        });
    }

    showSuccessAlert(title, msg, type){
        return swal({
            title: title,
            text: msg,
            icon: type,
            button: "OK",
        }).then((value) => {
                this.props.isFaculty  ?
                 window.location.href = '/faculty/news/list'
                 : window.location.href = '/news/general/news/list';
        });
    }

    render() {
        return (
            <>
                <Header isFaculty={this.props.isFaculty  ? true : false}/>
            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                <div className="toolbar" id="kt_toolbar">
                    <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                        <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
                            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">News
                            <span className="h-20px border-dark  border-start ms-3 mx-2"/>
                                {this.props.isFaculty  ?  "Faculty" :  "General" }
                                <span className="h-20px border-dark  border-start ms-3 mx-2"/>
                                Add News
                            </h1>
                        </div>
                    </div>
                </div>
                <div className="post d-flex flex-column-fluid" id="kt_post">
                    <div id="kt_content_container" className="container-fluid">
                        {/* CONTENT START */}
                        <div className="card mb-5 mb-xl-10">
									<div className="card-header">
										<div className="card-title">
											<h3>ADD NEWS</h3>
										</div>
									</div>
                                    <Newsform
                                    handleSubmit={this.handleSubmit}
                                    onEdit={this.onEdit}
                                    onImageChange={this.onImageChange}
                                    isLoading={this.state.isLoading}
                                    data={this.state.formData}
                                    image={this.state.htmlelement}
                                    />
								</div>
                        {/* CONTENT END */}
                 </div>
            </div>
            </div>
                </>
        )
    }
}



const mapStateToProps = (state) => {
    return {
        facultyData: state.facultyDetails,
        loginDetails: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(AddNews);