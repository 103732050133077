import React, {Component} from "react";
import {stateFromHTML} from "draft-js-import-html";
import {convertToRaw, EditorState} from "draft-js";
import draftToHtml from "draftjs-to-html";
import {Editor} from "react-draft-wysiwyg";
import SimpleFileUpload from "react-simple-file-upload";
import {projectName, projectUploadAPI} from "../../resources/url";
class LeadershipForm extends Component{
    constructor(props) {
        super(props);
        const contentBlocks = stateFromHTML(this.props.data.description);
        this.state = {
            editorState: EditorState.createWithContent(contentBlocks  !=="" ?  contentBlocks : ''),
        };
    }

    onEditorStateChange  = editorState => {
        this.setState({
            editorState,
        });
        this.props.data.description = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    };
    render() {
        const { editorState } = this.state;
        return (
            <form onSubmit={this.props.handleSubmit}>
                <div className="card-body"  style={{textAlign: 'left'}}>
                    <div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll text-left" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
                        <div className="row mb-5">
                            <div className="col-md-6 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Title</label>
                                <input
                                    type="text"
                                    className="form-control form-control-solid  mb-5"
                                    name="title"
                                    id="title"
                                    onChange={this.props.onEdit}
                                    value={this.props.data.title}
                                    placeholder="title"
                                />
                            </div>
                            <div className="col-md-6 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Full Name</label>
                                <input
                                    type="text"
                                    className="form-control form-control-solid  mb-5"
                                    name="fullname"
                                    id="fullname"
                                    onChange={this.props.onEdit}
                                    value={this.props.data.fullname}
                                    placeholder="Full Name"
                                />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-6 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Designation </label>
                                <input
                                    type="text"
                                    className="form-control form-control-solid  mb-5"
                                    name="designation"
                                    id="designation"
                                    onChange={this.props.onEdit}
                                    value={this.props.data.designation}
                                    placeholder="designation"
                                />
                            </div>
                             <div className="col-md-6 fv-row">
                                    <label className="required fs-5 fw-bold mb-2">Position</label>
                                    <select
                                        className="form-control form-control-solid"
                                        name="position"
                                        id="position"
                                        onChange={this.props.onEdit}
                                        value={this.props.data.position}
                                    >
                                        <option value="">Select position</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="18">18</option>
                                        <option value="19">19</option>
                                        <option value="20">20</option>
                                    </select>
                            </div>

                        </div>
                        <div className="row mb-5">
                            <div className="col-md-6 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Image</label>
                                {/*<SimpleFileUpload*/}
                                {/*    apiKey={simpleFileUploadAPIKey}*/}
                                {/*    maxFileSize={1}*/}
                                {/*    tag={simpleFileUploadFolderName + `-leadership`}*/}
                                {/*    onSuccess={this.props.onImageChange}*/}
                                {/*    preview="false"*/}
                                {/*    width="100%"*/}
                                {/*    height="100"*/}
                                {/*/>*/}
                                <div className="mb-3">
                                    <div className="fv-row mb-2">
                                        <div className="dropzone" id="kt_ecommerce_add_product_media" onClick={()=>{
                                            document.getElementById("photo").click()
                                        }}>
                                            <div className="dz-message needsclick">
                                                <i className="bi bi-image text-primary fs-3x"/>
                                                <div className="ms-4 col-md-9">
                                                    <h3 className="fs-5 fw-bold text-gray-900 mb-1">Click to upload.</h3>
                                                    <span className="fs-7 fw-semibold text-gray-400 text-info"> {this.props.data.image_name !== "" ? this.props.data.image_name : "Only .jpg, .png, .jpeg are allowed"}</span>
                                                </div>
                                                <div className="col-md-2">{this.props.image !== "" ? <img className="img-thumbnail" width={120} height={100} srcSet={this.props.image !== "" ? this.props.image : ""}/> : ""}</div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*<span className="alert-danger"> Max of 2MB file is allowed!</span>*/}
                                </div>
                                <input type="file"  id="photo" name={"photo"}  accept={"image/*"} onChange={this.props.onImageChange} hidden />
                                <div className="row">
                                    <div className="col-md-6">
                                        <span className="badge bg-danger mt-2">Max of 2MB file is allowed!</span>
                                    </div>
                                    <div className="col-md-6 pull-right text-right">
                                        <span className="badge bg-primary mt-2 w-100 ">Use 500 x 500 as image dimension.</span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Status</label>
                                <select
                                    className="form-control form-control-solid"
                                    name="status"
                                    id="status"
                                    onChange={this.props.onEdit}
                                    value={this.props.data.status}
                                >
                                    <option value="">Select Status</option>
                                    <option value="1">Active</option>
                                    <option value="0">Draft</option>
                                </select>
                            </div>


                        </div>

                        <div className="row mb-5">
                            <div className="col-md-12 fv-row">
                                <label className=" fs-5 fw-bold mb-2">Description</label>
                                <Editor
                                    className="form-control form-control-solid"
                                    rows={4}
                                    name="description"
                                    id="description"
                                    onEditorStateChange={this.onEditorStateChange}
                                    wrapperClassName="form-control form-control-solid"
                                    editorClassName="form-control form-control-solid"
                                    editorState={editorState}
                                />
                                {/*<blockquote dangerouslySetInnerHTML={{__html: `${this.state.alumniSlider.Description} <br/> - <b> {this.state.alumniSlider.StudentName}</b>`}} style={{ fontSize: 13 }}/>*/}
                            </div>
                        </div>

                        <button type="submit" id="kt_modal_new_address_submit" data-kt-indicator={this.props.isLoading} className="btn btn-primary w-25 offset-sm-4 mb-9 mt-9">
                            <span className="indicator-label">Submit</span>
                            <span className="indicator-progress">Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                        </button>
                    </div>
                </div>
            </form>
        );
    }
}

export default LeadershipForm;