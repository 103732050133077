import React, {Component} from "react";
import { connect } from "react-redux";
import {
    setgeneralDetails
} from "../../actions/setgeneraldetailsaction";
import { Link } from "react-router-dom";
import { Oval } from  'react-loader-spinner'
import {projectURL, serverLink, shortCode} from "../../resources/url";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import axios from "axios";
import Dateformat from "../util/dateformat";
import Header from "../Header/header";
class NewsList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			newsList: [],
            columns: [
                {
                    name: "Title",
                    selector: row => `${row.Title}`,
                    sortable: true,
                    width: '25%'
                },
                {
                    name: "Authors",
                    selector: row => `${row.InsertedBy}`,
                    sortable: true
                },
                {
                    name: "Images",
                    selector: row => <img src={row.ImagePath !== null ? row.ImagePath.includes("simplefileupload") ? row.ImagePath :`${serverLink}public/uploads/${shortCode}/news_uploads/${row.ImagePath}` : ""}
                                                                     className="w-65px me-3" alt=""/>,
                    sortable: true
                },
                {
                    name: "Date",
                    selector: row =>  <Dateformat date={row.InsertedDate} />,
                    sortable: true
                },
                {
                    name: "Viewers",
                    selector: row => `${row.ViewCount}`,
                    sortable: true
                },
                {
                    name: "Status",
                    selector: row => `${row.Status === 1 ? "Published" : "Draft"}`,
                    sortable: true
                },
                {
                    name: "Action",
                    cell: (row) => (
                        <a href={`${projectURL}news/${row.Slug}`} target="_blank" className="btn btn-primary btn-sm">
                            <i className="fa fa-eye" aria-hidden="true"/>
                        </a>

                    ),
                }, 
                {
                    name: "Action",
                    cell: (row) => (
                        <Link to={this.props.isFaculty  ? "/faculty/news/update" : "/news/general/update"} >
                            <button
                                type="button"
                                onClick={async () => {
                                    const newsData = {
                                        ...row
                                    };
                                    this.props.setOnGeneralDetails(newsData);
                                }}
                                className="btn btn-primary btn-sm"
                            >
                                <i className="fa fa-edit" aria-hidden="true"/>
                            </button>
                        </Link>
                    ),
                },

            ],
            data: []
		}
	}

    componentDidMount() {
        this.fetchNewsList();
    }

    async fetchNewsList() {
        await axios.get(`${serverLink}news/list/all/${this.props.isFaculty  ? this.props.facultyData.FacultyCode : "General"}`)
            .then(data => {
                const result = data.data;
                result.map((data,index)=>{
                        this.setState({
                            data: [...this.state.data,
                                {
                                    EntryID: data.EntryID,
                                    Title: data.Title,
                                    InsertedBy: data.InsertedBy,
                                    FacultyCode: data.FacultyCode,
                                    ImagePath: data.ImagePath,
                                    InsertedDate: data.InsertedDate,
                                    Description: data.Description,
                                    ViewCount: data.ViewCount,
                                    Status: data.Status,
                                    Slug: data.Slug,
                                    rowData: data
                                }
                            ]

                        });
                })
            });
        this.setState({isLoading: false})
    }


    render() {
        const columns = this.state.columns;
        const data = this.state.data;
        const tableData = {
            columns,
            data,
            print: false,
            exportHeaders: true,
            filterPlaceholder: 'Search News',

        };

        let count = 1;
        return (
            <>
                <Header isFaculty={this.props.isFaculty ? true : false}/>
            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                <div className="toolbar" id="kt_toolbar">
                    <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                        <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
                            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">News
                            <span className="h-20px border-dark  border-start ms-3 mx-2"/>
                                {this.props.isFaculty  ? "Faculty" : "General"}
                                <span className="h-20px border-dark  border-start ms-3 mx-2"/>
                                News List
                            </h1>
                        </div>
                    </div>
                </div>
                <div className="post d-flex flex-column-fluid" id="kt_post">
                    <div id="kt_content_container" className="container-fluid">
                        {/* CONTENT START */}
						{this.state.isLoading ?
							<div style={{margin: 'auto', position: 'relative', justifyContent:'center', alignItems: 'center', display: 'flex' , height: '400px'}}>
								<Oval
									type="Oval"
									color="#eeeeee"
									height={50}
									width={50}
									secondaryColor="teal"
									timeout={15000} //3 secs
								/>
							</div>
							:
                        <div className="card mb-5 mb-xl-10">
									<div className="card-header">
										<div className="card-title">
											<h3>NEWS LIST</h3>
										</div>
                                        <span className="mt-5" style={{float: 'right'}}>
                                            <Link to={this.props.isFaculty ? "/faculty/news/add" : "/news/general/add"} className="btn btn-primary">
                                                <i className="fa fa-plus"/> Add News
                                            </Link>
                                        </span>
                                            
									</div>
                                  
									<div className="card-body"  style={{textAlign: 'left'}}>
                                        <DataTableExtensions
                                            {...tableData}
                                        >
                                            <DataTable
                                                noHeader
                                                defaultSortField="id"
                                                defaultSortAsc={false}
                                                pagination
                                                highlightOnHover/>
                                        </DataTableExtensions>

						            </div>

								</div>}

                        {/* CONTENT END */}
                 </div>
            </div>
            </div>
                </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        facultyData: state.facultyDetails,
    };
};


const mapDisptachToProps = (dispatch) => {
    return {
        setOnGeneralDetails: (p) => {
            dispatch(setgeneralDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDisptachToProps)(NewsList);