import React, { Component } from "react";
import {projectName, projectUploadAPI, serverLink, shortCode} from "../../../resources/url";
import SimpleFileUpload from "react-simple-file-upload";
class PartnersForm extends Component {
    render() {
        return (
            <form onSubmit={this.props.handleSubmit}>
                <div className="card-body" style={{ textAlign: 'left' }}>
                    <div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll text-left" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
                        <div className="row mb-5">
                            <div className="col-md-12 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Partner Name</label>
                                <input
                                    type="text"
                                    className="form-control form-control-solid  mb-5"
                                    name="PartnerName"
                                    id="PartnerName"
                                    onChange={this.props.onEdit}
                                    value={this.props.data.PartnerName}
                                    placeholder="Partner Name"
                                />
                            </div>
                            <div className="col-md-6 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Image</label>
                                {/* <SimpleFileUpload
                                    apiKey={projectUploadAPI}
                                    onSuccess={this.props.onImageChange}
                                    tag={`${projectName}-partners`}
                                    accepted="image/*"
                                    maxFileSize={1}
                                    preview="true"
                                    width="100%"
                                /> */}
                                <small className="text-danger"><strong>File must not exceed 1MB</strong></small>
                                <input
                                    type="file"
                                    className="form-control form-control-solid  mb-5"
                                    name="photo"
                                    id="photo"
                                    onChange={this.props.onImageChange}
                                    accept="image/*"
                                />
                                {/*{console.log(this.props)}*/}
                                {
                                    this.props.data.photo !== "" &&
                                    <img src={this.props.data.photo !== null ?
                                        this.props.data.photo.includes("simplefileupload.com") ?
                                            this.props.data.photo : `${serverLink}public/uploads/${shortCode}/partners_uploads/${this.props.data.photo}` : ''}
                                        style={{maxWidth : "400px", maxHeight : "400px"}}
                                    />
                                }

                            </div>
                            <div className="col-md-6 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Status</label>
                                <select
                                    className="form-control form-control-solid"
                                    name="Status"
                                    id="Status"
                                    onChange={this.props.onEdit}
                                    value={this.props.data.Status}
                                >
                                    <option value="">Select Status</option>
                                    <option value="1">Publish</option>
                                    <option value="0">Draft</option>
                                </select>
                            </div>
                        </div>
                        <button type="submit" id="kt_modal_new_address_submit" data-kt-indicator={this.props.isLoading} className="btn btn-primary w-25 offset-sm-4 mb-9 mt-9">
                            <span className="indicator-label">Submit</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </div>
            </form>
        );
    }
}

export default PartnersForm;