import React, { Component } from "react";
import "./App.css";
import PageRoutes from "./component/page_routes/page_routes";
import SideBar from "./component/sidebar/sidebar";
import { BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import Login from "./component/login/login";

class App extends Component {
    constructor() {
        super();
        this.state = {
            isLoggedIn: window.sessionStorage.getItem('isLoggedIn')
        }
    }
  render() {
    return (
      <div className="App">
          {
              !this.state.isLoggedIn ?
                  <Login/>
                  :
                  <Router>
                      <SideBar />
                      <PageRoutes />
                  </Router>
          }
      </div>
    );
  }
}

export default App;
