import React, {Component} from "react";
import axios from "axios";
import swal from 'sweetalert';
import {serverLink} from "../../resources/url";
import EventForm from "./eventform";
import Header from "../Header/header";
import {connect} from "react-redux";

class AddEvent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: "off",
            imagefile: "",
            htmlelement: "",
            formData: {
                Title: "",
                Description: "",
                EventDate: "",
                StartTime: "",
                EndTime: "",
                Location: "",
                FacultyCode: this.props.isFaculty  ? this.props.facultyData.FacultyCode : "General",
                Status: "",
                photo: "",
                ImagePath: "",
            },
        }
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: "on",
        });
        this.onAddEvent();
    };

    async onAddEvent() {
        let sendData = {
            ...this.state.formData,
            InsertedBy: `${this.props.loginDetails[0].FirstName} ${this.props.loginDetails[0].MiddleName} ${this.props.loginDetails[0].Surname}`,
        };

        if(this.state.formData.Title === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please enter event title", "error")
            return false;
        }

        if(this.state.formData.EventDate === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please select event date", "error")
            return false;
        }

        if(this.state.formData.StartTime === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please select event start time", "error")
            return false;
        }

        if(this.state.formData.EndTime === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please select event end time", "error")
            return false;
        }

        if(this.state.formData.Location === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please select event location", "error")
            return false;
        }

        if(this.state.formData.Description === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please enter event content", "error")
            return false;
        }

        axios.post(`${serverLink}event/add`, sendData)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if(response === "event exist") {
                    this.showAlert("Oops!", "Event with the same name already exist", "error")
                    return false;
                } else if (response === "failed") {
                    this.showAlert("Oops!", "Something went wrong adding the event. Please try again!", "error")
                    return false;
                } else if (response === "success"){
                    if (this.state.imagefile !== "") {
                        const formData = new FormData();
                        formData.append('photo', this.state.imagefile);
                        formData.append('entry_id', result.data.entry_id)
                         axios.patch(`${serverLink}event/uploadEventPhoto`, formData)
                            .then(result => {
                                this.setState({
                                    isLoading: false,
                                });
                                // const res = result.data.lastID;
                            })
                            .catch(err => {
                                this.setState({
                                    isLoading: false,
                                });
                                console.error('ERROR', err);
                            });
                    }
                    this.showSuccessAlert("SUCCESS", "EVENT ADDED SUCCESSFULLY", "success")
                    return false;
                } else {
                    this.showAlert("Oops!", "Failed to add the event details!", "error")
                    return false;
                }

            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                console.error('ERROR', err);
            });
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    onEdit = (e) => {
        const formData = this.state.formData;
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    // onImageChange = (event) => {
    //     const formData = this.state.formData;
    //     this.setState({formData: {
    //             ...formData, photo: event
    //         },});
    //     // if (event.target.files && event.target.files[0]) {
    //     //     this.setState({
    //     //         imagefile: event.target.files[0],
    //     //     });
    //     //
    //     //     let reader = new FileReader();
    //     //     reader.onload = (e) => {
    //     //         this.setState({
    //     //             htmlelement: (
    //     //                 <div className="addpersonpage">
    //     //                     <img
    //     //                         className="netimage"
    //     //                         srcSet={e.target.result}
    //     //                         alt="profileImage"
    //     //                     />
    //     //                 </div>
    //     //             ),
    //     //         });
    //     //     };
    //     //     reader.readAsDataURL(event.target.files[0]);
    //     //     this.setState({});
    //     // }
    // };

    onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0]
            if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {
            } else {
                this.showAlert("Oops!", "Only .png, .jpg and .jpeg format allowed!", "error")
                return false;
            }
            if (file.size > 2000000) {
                this.showAlert("Oops!", "max file size is 2mb", "error")
                return false;
            }

            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
                this.setState({
                    imagefile: event.target.files[0],
                    htmlelement: e.target.result,
                    image_name: file.name,
                    view: file.mozFullPath,
                });
            };
        }
    };

    showAlert(title, msg, type){
        return swal({
            title: title,
            text: msg,
            icon: type,
            button: "OK",
        });
    }

    showSuccessAlert(title, msg, type){
        return swal({
            title: title,
            text: msg,
            icon: type,
            button: "OK",
        }).then((value) => {
            this.props.isFaculty  ?
                window.location.href = '/faculty/event/list'
                : window.location.href = '/event/general/list';
        });
    }

    render() {
        return (
            <>
                <Header isFaculty={this.props.isFaculty  ? true : false}/>
            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                <div className="toolbar" id="kt_toolbar">
                    <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                        <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
                            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">Event
                                <span className="h-20px border-dark  border-start ms-3 mx-2"/>
                                {this.props.isFaculty  ?  "Faculty" :  "General" }
                                <span className="h-20px border-dark  border-start ms-3 mx-2"/>
                                Add Event
                            </h1>
                        </div>
                    </div>
                </div>
                <div className="post d-flex flex-column-fluid" id="kt_post">
                    <div id="kt_content_container" className="container-fluid">
                        {/* CONTENT START */}
                        <div className="card mb-5 mb-xl-10">
                            <div className="card-header">
                                <div className="card-title">
                                    <h3>ADD EVENT</h3>
                                </div>
                            </div>
                            <EventForm
                                handleSubmit={this.handleSubmit}
                                onEdit={this.onEdit}
                                isLoading={this.state.isLoading}
                                onImageChange={this.onImageChange}
                                data={this.state.formData}
                                image={this.state.htmlelement}
                            />
                        </div>
                        {/* CONTENT END */}
                    </div>
                </div>
            </div>
                </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        facultyData: state.facultyDetails,
        loginDetails: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(AddEvent);