export const setFacultyDetails = (p) => {
    return {
        type: "SET_FACULTY_DETAILS",
        payload: p,
    };
};

export const setFacultyData = (p) => {
    return {
        type: "SET_FACULTY_DATA",
        payload: p,
    };
};

export const setCourseDetails = (p) => {
    return {
        type: "SET_COURSE_DETAILS",
        payload: p,
    };
};

export const setFacultyNavbar = (p) => {
    return {
        type: "SET_FACULTYNAVBAR_DETAILS",
        payload: p,
    };
};